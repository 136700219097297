import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'

const FaqsPage = ({ data }) => {
  const { html } = data.content.childMarkdownRemark

  return (
    <Layout>
      <PageHeader title="Frequently Asked Questions" seo />
      <div className="container">
        <FaqsContainer dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

const FaqsContainer = styled.article`
  ol {
    display: grid;
    grid-gap: 40px 80px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding-left: 12px;
  }

  h2 {
    font-size: 1.9rem;
    margin: 0 0 16px;
    font-weight: 500;
  }
`

export default FaqsPage

export const pageQuery = graphql`
  query {
    content: file(name: { eq: "faqs" }) {
      childMarkdownRemark {
        html
      }
    }
  }
`
